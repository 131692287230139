.nav__list {
  margin-right: 1.5em;
  display: flex;
  padding: 0 1em; /* Add padding to the left and right */
}
.nav__list-item {
  margin-left: 1.5em;
  padding: 0.5em 1em; /* Add padding to each item */
}

.app .nav__hamburger {
  display: none;
}

.nav__theme {
  margin-top: 0.4em;
}

@media (max-width: 600px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
    align-items: flex-end; /* Align items to the right */
    position: fixed;
    top: 60px; /* Adjust this value based on the height of your navbar */
    right: 0;
    width: auto; /* Adjust width as needed */
    height: auto; /* Adjust height as needed */
    padding: 1em;
    border: 2px solid var(--clr-primary);
    z-index: 2;

  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}
