.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project:hover {
  /* transform: translateY(-7px); */
  box-shadow: 0 0 30px #3838ff;
}

.project__screenshot {
  width: 200px; /* Fixed width */
  height: 150px; /* Fixed height */
  object-fit: cover; /* Cover the container, might crop the image */
  margin-top: 1.2em;
  align-self: center; /* Center the screenshot */
}

.project__description {
  margin-top: 1em;
  flex-grow: 1; /* Allow description to grow */
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
  align-self: flex-end; /* Align link icon to the bottom */
}

.project .project__button {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

/* Target the second button specifically when it wraps */
.project .project__button + .project__button {
  margin-top: 1em;  /* Only applies when buttons stack */
}

.project .project__button:hover {
  background-color: #0056b3;
}

.project__button.no-underline::before {
  content: none;
}
