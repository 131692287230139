.header {
  height: 8em;
  max-width: 100%; /* Ensure the header spans the full width */
  width: 100%; /* Ensure the header spans the full width */
  margin: 0; /* Remove auto margin to prevent centering */
  justify-content: space-between;
  box-shadow: 0 2px 4px var(--clr-primary); /* Add shadow effect */
  padding: 0 1em; /* Add padding to the left and right */
}

@media (max-width: 600px) {
  .header {
    height: 6em;
  }
}
